.contact-section {
    background-color: $color-red;
    padding: 40px 15px;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }

    @include media-breakpoint-down(xs){
       padding-bottom: 20px;
    }

  }

  .contact-section-inner {
    @extend .container;
    color: white;
    text-align: center;

    &::after {
        background-image: url('../Images/layout/brush-red.svg');
        background-repeat: no-repeat;
        display: inline-block;
        width: 100%;
        height: 122px;
        content:"";
        position: absolute;
        left: 0;
        bottom: -48px;
        z-index: -1;

        @include media-breakpoint-down(xs){
            height: 55px;
        }
    }
  }

  .contact-intro {
    @include make-col(12);
    h2 {
      @extend h2;
      background: none;
      font-size: $font-size-h1;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      margin-bottom: 1.5rem;

      @include media-breakpoint-down(xs) {
          margin-bottom: 12px;
      }

    }

    p {
      margin-bottom: 1.5rem;
      font-weight: 200;
    }
  }

  .contact-content {
    @include make-col(12);

    ::placeholder {
        color: white;
    }

    .content-left, .content-right {
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
  }

  .contact-column {
    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
  }

  .contact-firstname,
  .contact-lastname {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
  }

  @include media-breakpoint-up(sm) {
    .contact-firstname {
      padding-right: 0;
    }
  }

  .contact-privacy {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;

    input {
      width: 20px;
      margin-top: 6px;
    }

    .contact-privacy-description {
      @extend .col;
      font-size: 1rem;
      font-weight: 200;
      text-align: left;

        a {
            color: inherit;
        }
    }
  }

  .contact-submit {
    @include media-breakpoint-up(md) {
      @include make-col(12);

    }
    input {
        border: 1px solid;
    }

    margin-top: 15px;
  }

  .contact-confirmation,
  .contact-error {
    display: none;
    border: 0;
    border-radius: 0;

    &.alert-success {
      background-color: $brand-primary;
      color: $color-white;
    }

    b {
      font-weight: bold;
    }

    .debug {
      font-size: $font-size-small;
    }
  }

  .form-control {
    border: none;
    border-radius: 0;
    background-color: #CE4E51;
    font-weight: $font-weight-light;

  }
