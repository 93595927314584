.neos-nodetypes-text {
  &.color-primary {
    color: $brand-primary;
  }

  &.noMb {
      p {
        margin-bottom: 0
      }
  }

  ul {
      @extend p;
      list-style: none;

      li {
        margin-bottom: 10px;
      }

      li::before {
        content: "\2022";
        font-weight: 600;
        display: inline-block;
        width: 18px;
        margin-left: -18px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 4px;
        }
    }
  }
  margin:  0 95px;

  @include media-breakpoint-down(xs) {
    margin:  0 0;
  }

  &.noMl {
      margin-left: 0;
  }

  &.noMr {
      margin-right: 0;
  }

  &.bolder {
      strong {
          font-weight: 600;
      }
  }
}

.neos-nodetypes-headline {
    margin:  0 95px;

    @include media-breakpoint-down(xs) {
        margin:  0 0;
    }

    &.likeH3 {
        h2, h4 {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 20px;
            margin-top: 42px;
            line-height: 30px;
            text-transform: unset;
            letter-spacing: unset;
        }
    }

    &.noMT {
        h1, h2, h3, h4 {
            margin-top: 0;
        }
    }

    &.noMo {
        margin: auto 0;
    }
}

.neos-nodetypes-image {
    figcaption {
        background-color: #CACDCE;
        padding-top: 20px;
        padding-bottom: 30px;

        strong {
            font-size: 22px;
        }

        p {
            font-size: 16px;
        }
    }
}

.neos-nodetypes-text-with-image  {
    figure {
        margin-bottom: 0;
    }

    p {
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 16px;
        color: $color-black;
    }
}

.jonnitto-plyr-video{
    position: relative;

    .unmute-wrapper {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 100px;
        right: 100px;
        z-index: 1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(lg) {
            top: 15px;
            right: 15px;
            width: 80px;
            height: 80px;
        }

        @include media-breakpoint-down(md) {
            width: 50px;
            height: 50px;
        }
    }

    .unmute-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        transition: all ease-in-out 0.5s;
        background-image: url('../Images/layout/annweiler-speaker-mute.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 35px;
        height: 35px;

        @include media-breakpoint-down(lg) {
            width: 20px;
            height: 20px;
        }

        @include media-breakpoint-down(md) {
            width: 15px;
            height: 15px;
        }

        &.unmute-icon {
            background-image: url('../Images/layout/annweiler-speaker.svg');
        }
    }

    .pulse {
        &::before, &::after {
            width: 100px;
            height: 100px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 3px solid #FFF;
            border-radius: 50%;
            animation: pulse 3s linear infinite;
            background: transparent;
            box-shadow: 0 0 8px rgba(#fff, .3);

            @include media-breakpoint-down(lg) {
                width: 80px;
                height: 80px;
            }

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }
        }

        &::after {
            animation-delay: 1s;

        }
    }


    @keyframes pulse {
        0% {
            transform: scale(.5);
        }

        60% {
            transform: scale(.85);
        }
        100% {
            opacity: 0;
        }
    }
}
