.organisation--wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    figure {
        margin-bottom: 0;
        margin-right: 28px;
        width: 100%;
        max-width: 55px;

        img {
            width: 80px;
            height: auto;
        }
    }

    p {
        margin-bottom: 0;
        line-height: 26px;
        color: $color-black;
    }
}
