.reference {
    a {
        color: $color-black;
    }

    .reference-image {
        position: relative;
        margin: auto;
        overflow: hidden;

        figure {
            margin-bottom: 0;

        }

        img {
            transition: all 0.2s;
            height: auto;
            min-height: 274px;
            width: 100%;
            object-fit: cover;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .reference-description {
        background-color: rgba($color: #000000, $alpha: 0.1);
        padding: 30px 34px 80px;
        height: 200px;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding: 20px 15px 30px;
            height: auto;
            min-height: 193px;
        }

        @include media-breakpoint-down(sm) {
            min-height: auto;
        }


        h3 {
            font-size: 22px;
            margin-bottom: 22px;
            margin-top: 0;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }

        h4 {
            font-size: 16px;
            line-height: 22px;
        }
    }
}
