.job-wrapper {
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 99;
    overflow-x: clip;
    overflow-y: unset;
    width: 100%;
    max-width: 500px;
    visibility: hidden;

    @media (max-width: 1440px) {
        max-width: 26%;
        padding-left: 20px;
        transition: all ease-in-out 1s;
    }

    @include media-breakpoint-down(lg) {
        max-width: 35%;
    }

    @include media-breakpoint-down(md) {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        max-height: 60px;
        padding-left: 0;
    }

    @include media-breakpoint-down(sm) {
        max-height: 55px;
    }

    > div {
        position: relative;
        transform: translateX(45%);
        background-color: white;
        box-shadow: -5px 5px 14px -4px rgba($color: #000000, $alpha: 0.2);
        padding: 20px 32px 26px;
        text-align: center;
        transition: all ease-in-out 1s;
        width: 345px;

        @media (max-width: 1440px) {
            transform: translateX(5%);
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            box-shadow: -5px 5px 14px 5px rgba($color: #000000, $alpha: 0.2);
            transform: translateX(0) translateY(0);
        }
    }

    &.close {
        overflow: unset;

        @media (max-width: 1440px) {
            max-width: 60px;
            transition: all ease-in-out 1s;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
            max-height: 100%;
        }

        > div {
            transition: all ease-in-out 1s;
            transform: translateX(132%);

            @media (max-width: 1440px) {
                transform: translateX(2%);
            }

            @include media-breakpoint-down(md) {
                transform: translateX(0) translateY(0);
            }

            .arrow {
                &::before {
                    content: '';
                    display: block;
                    position: relative;
                    left: 50%;
                    top: 50%;
                    cursor: pointer;
                    transform: translateX(-50%) translateY(-50%);
                    transition: all ease-in-out 1s;

                    @include media-breakpoint-down(md) {
                        transform:translateX(-50%) translateY(-50%) rotate(-90deg);
                    }
                }
            }
        }
    }

    p {
        font-family: $slick-font-family;

        &:first-child {
            text-transform: uppercase;
            letter-spacing: 0.04rem;
            font-weight: 500;
            margin-bottom: 8px;
        }

        &:nth-child(2) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    .arrow {
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $color-red;
        border-radius: 20px;
        width: 30px;
        height: 30px;
        transition: all ease-in-out 1s;

        @include media-breakpoint-down(md) {
            top: -2px;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }

        &:hover {
            transform-origin: center;
            transform: translateY(-50%) scale(1.2);
            transition: all ease-in-out 0.5s;

            @include media-breakpoint-down(md) {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }

        &::before {
            content: '';
            display: block;
            position: relative;
            left: 50%;
            top: 50%;
            cursor: pointer;
            transform: translateX(-50%) translateY(-50%) rotate(180deg);
            background-image: url('../Images/layout/arrow-white.svg');
            background-repeat: no-repeat;
            background-size: contain;
            height: 16px;
            width: 12px;
            transition: all ease-in-out 1s;

            @include media-breakpoint-down(md) {
                transform: translateX(-50%) translateY(-50%) rotate(90deg);
            }
        }
    }
}
