#content-slider.reference--slider.carousel {
    margin-bottom: 50px;
    margin-top: 60px;

    .neos-contentcollection {
        overflow: hidden;
    }

    @include media-breakpoint-down(md) {
        margin-top: 50px;
      }

    @include media-breakpoint-down(sm) {
      margin-bottom: 50px;
    }

    .carousel-indicators {
      display: flex;
      bottom: -50px;
      z-index: 0;

      @include media-breakpoint-down(lg) {
        bottom: -40px;
      }

      @include media-breakpoint-down(sm) {
        display: contents;
      }

      li {
        max-width: 170px;
        text-align: center;
        font-size: 14px;
        width: auto;
        text-indent: 0;
        background: none;
        padding: 0 15px;
        line-height: 1.3;

        @include media-breakpoint-down(sm) {
          height: 18px;
          max-width: 100%;
        }
      }
    }

    .carousel-control-prev {

        @media (max-width: 1200px) {
            left: -20px !important;
        }

        @include media-breakpoint-down(sm) {
            left: -18px !important;
        }

      .carousel-control-prev-icon {
          left: 30px;
          top: 500px;

          @include media-breakpoint-down(lg) {
              top: 420px;
          }

          @include media-breakpoint-down(md) {
             top: 376px;
             height: 35px;
          }

          @include media-breakpoint-down(sm) {
              height: 30px;
              top: 175px;
              left: 0;
          }

          @media (max-width: 320px) {
            top: 162px;
            left: -3px;
          }
      }

      @include media-breakpoint-down(sm) {
        left: 0;
      }
  }

    .carousel-control-next {

        @media (max-width: 1200px) {
            right: -20px !important;
        }

        @include media-breakpoint-down(sm) {
            right: -18px !important;
        }

        .carousel-control-next-icon {
            right: 30px;
            top: 500px;

            @include media-breakpoint-down(lg) {
                top: 420px;
            }

            @include media-breakpoint-down(md) {
              top: 376px;
              height: 35px;
            }

            @include media-breakpoint-down(sm) {
              height: 30px;
              top: 175px;
              right: 0;
            }

            @media (max-width: 320px) {
              top: 162px;
              right: -3px;
            }
        }

        @include media-breakpoint-down(sm) {
            right: 0;
          }
    }

    .carousel-control-next, .carousel-control-prev {
        @include media-breakpoint-down(sm) {
            top: 35%;
            transform: translateY(-50%);
        }
    }

    .carousel-item, .carousel-item-backend {
        min-height: 600px;
        height: auto;

        @include media-breakpoint-down(md) {
            min-height: 500px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 625px;
        }

        .reference-section {
            display: flex;

            @include media-breakpoint-down(sm) {
                display: block;
            }

            .reference-content--wrapper {
                @include make-col(6);
                @extend .col;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                margin-left: 35px;
                .reference-quote {
                    p {
                        font-size: 30px;
                        font-weight: 500;
                        font-style: italic;
                        line-height: 50px;
                        color: $color-red;
                        margin-bottom: 20px;
                        text-align: right;

                        @include media-breakpoint-down(md) {
                            font-size: 25px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 20px;
                            line-height: 30px;
                            margin-bottom: 12px;
                            text-align: center;
                        }
                    }
                }

                .reference-author {
                    p {
                        text-align: right;
                        font-size: 16px;

                        @include media-breakpoint-down(sm) {
                            text-align: center;
                        }
                    }
                }
            }

            .reference-image {
                @extend .col;
                margin-right: 35px;
                text-align: center;

                .reference-image {
                    padding: 0;
                    img {
                        max-width: 100%;

                        &:hover {
                            transform: none;
                        }
                    }

                    max-height: auto !important;

                    &:hover {
                        transform: none !important;
                    }
                }
            }
        }

        .reference-button {
           display: block;
           text-align: center;
           margin-top: 95px;

           @include media-breakpoint-down(md) {
               margin-top: 65px;
           }

           @include media-breakpoint-down(sm) {
               margin-top: 30px;
           }
        }
    }

    .carousel-item-backend {
        margin-bottom: 50px;
    }
  }
