.quote-section {

    @include media-breakpoint-down(md){
        @include make-col(12);
    }

    .quote {
        margin-top: 27px;

        > p {
            font-size: 30px;
            font-weight: 600;
            font-style: italic;
            line-height: 50px;
            color: $color-red;
            margin-bottom: 35px;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 12px;
            }
        }
    }

    .author > p {
        font-size: 16px;
    }


    &.left {
        .quote, .author {
            text-align: right;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .quote {
            padding-left: 5%;
            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

        }

        .author > p {
            padding-left: 35%;
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                margin-bottom: 25px;
            }
        }

        &::after {
            clear: both;
        }
    }

    &.right {
        .quote, .author {
            text-align: left;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .quote {
            padding-right: 5%;

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }
        }

        .author > p {
            padding-right: 35%;

            @include media-breakpoint-down(sm) {
                padding-right: 0;
            }
        }
    }
    &::after {
        clear: both;
    }
}
