.teaser {
    @include make-col(12);
    margin-bottom: 73px;

    .services {
        display: inline-flex;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        @include media-breakpoint-down(lg) {
            display: block;
            text-align: center;
        }

        .services-icon {
            .restaurierung-icon, .heatinsulation-icon, .walldecoration-icon, .stuccodesign-icon, .scaffolding-icon {
                width: 71px;
                height: 71px;
                background-color: $color-red;
                padding: 15px;

                img {
                    width: 100%;
                    max-height: 44px;
                }
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
    }

    .teaser-img {
        @extend .services-box;
        margin-left: 0;
    }

    .teaser-content {
        // @include make-col(12);
        margin-left: 30px;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
            margin-left: 0;
        }

        p, h3, span {
            font-size: 18px;

            &:first-child {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 26px;
                margin-top: 0;
            }

            &:nth-child(2) {
                margin-bottom: 35px;
                font-weight: $font-weight-light;

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
