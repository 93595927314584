.grid-content-col {
  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }
    &.references {
        position: relative;
        text-align: center;
        margin-bottom: 40px;
    }

    &.center {
        text-align: center;
    }

    &.mb25 {
        margin-bottom: 25px;

        &:nth-child(2n) {
            .button {
                &.fullWidth {
                    justify-content: flex-end;

                    @include media-breakpoint-down(md) {
                        justify-content: center
                    }
                }
            }
        }
    }

    &.col-10.offset-1, &.col-8.offset-1{
        @include media-breakpoint-down(lg) {
            @include make-col(12);
          margin-left: 0 !important;
          width: 100%;
        }
    }

    &.col-12.offset-3{
        @include media-breakpoint-down(lg) {
          margin-left: 0 !important;
          width: 100%;
        }
    }

    &.col-12 {
    .jonnitto-prettyembed-wrapper {
        margin:  0 95px;

            @include media-breakpoint-down(xs) {
                margin:  26px 0 0;
            }
        }
    }

    &.col-4 ,&.col-6 {
        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.grid-content.full {
    @extend .container-fluid;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;

    @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 40px;
    }

    .grid-content-col {

      &.col-12 {
        @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .neos-nodetypes-image {
          img {
            width: 100%;
          }
        }
      }
    }

    .grid-content-container {
        @extend .container;

        .neos-contentcollection {
            justify-content: center;
        }
    }

    &.backgroundcolor {
        background-color: #E9ECED;

        &::after {
            background-image: url('../Images/layout/brush-grey.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            width: 100%;
            height: 122px;
            content:"";
            position: absolute;
            left: 0;
            bottom: -58px;
            z-index: -1;


            @include media-breakpoint-down(md){
                background-size: 100% 100%;
            }
        }
    }

    &.map {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 0;
        .grid-content-container {
            max-width: 100% !important;
            width: 100%;

            .grid-content-col.col-12 {
                padding: 0;

                .neos-nodetypes-html {
                    iframe {
                        display: block;
                    }
                }
            }
        }
    }

    &.distance {
        margin-bottom: 0;

        &::after{
            bottom: -23px;
        }
    }

    &.timeline-container {
        .grid-content-container {
           max-width: 100%;
           width: 100%;
            .grid-content-col {

                &.col-12 {
                padding-left: 0;
                padding-right: 0;
                }
            }

           .neos-contentcollection {
                > .neos-nodetypes-headline {
                    &:first-child {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
           }
        }
        padding-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

.grid-content {
    @extend .container;
    margin-bottom: 120px;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
        margin-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 65px;
    }

    &.services {
        height: 160px;

        @media (max-width: 1440px){
            height: 100%;
        }
    }

    &.services-overview {
        .neos-nodetypes-text {
            margin: 0;

            a {
                text-decoration: none;
                color: inherit;
                position: relative;

                &::after {
                    content:  url("../Images/layout/arrow-overview.svg");
                    position: absolute;
                    right: -28px;
                    top: 0;
                    bottom: 0;
                    display: inline-block;
                    width: 20px;
                }
            }

            a:hover {
                font-weight: 500;
                &::after {
                    transform: translateX(+50%);
                    transition: all 0.5s ease;
                    cursor: pointer;
                }
            }

            ul  {
                li {
                    color: rgba($color: #000000, $alpha: 0.6);
                    &::before {
                        content: none;
                    }
                }
            }

            p {
                margin-bottom: 0;

                strong {
                    font-weight: 600;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-left: 8%;

                p {
                    line-height: 32px;
                }
            }

            @include media-breakpoint-down(xs) {
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(md) {
            .grid-content-col.col-4 {
                @include make-col(6);

                &:nth-child(4) {
                    @include make-col(12);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .grid-content-col.col-4 {
                @include make-col(12);
            }
        }
    }

    &.backgroundimage-colorrole {
        background-image: url('../Images/layout/farbrolle.svg');
        background-repeat: no-repeat;
        background-size: 20%;

        @media (min-width: 2560px) {
            background-size: 15%;
        }

        @include media-breakpoint-down(md) {
            background-size: 50%;
        }

        @include media-breakpoint-down(sm) {
            background-size: 80%;
        }
    }

    &.backgroundimage-pillar {
        background-image: url('../Images/layout/saule.svg');
        background-repeat: no-repeat;
        background-size: 20%;

        @media (min-width: 2560px) {
            background-size: 15%;
        }

        @include media-breakpoint-down(md) {
            background-size: 50%;
        }

        @include media-breakpoint-down(sm) {
            background-size: 80%;
        }
    }

    &.backgroundimage-stucco {
        background-image: url('../Images/layout/stuckgestaltung_grey.svg');
        background-repeat: no-repeat;
        background-size: 20%;

        @media (min-width: 2560px) {
            background-size: 15%;
        }

        @include media-breakpoint-down(md) {
            background-size: 50%;
        }

        @include media-breakpoint-down(sm) {
            background-size: 80%;
        }
    }

    &.backgroundimage-insulation {
        background-image: url('../Images/layout/daemmung_grey.svg');
        background-repeat: no-repeat;
        background-size: 20%;

        @media (min-width: 2560px) {
            background-size: 15%;
        }

        @include media-breakpoint-down(md) {
            background-size: 50%;
        }

        @include media-breakpoint-down(sm) {
            background-size: 80%;
        }
    }

    &.distance {
        margin-bottom: 0;

        &::after{
            bottom: -23px;
        }
    }

    &.noMt {
        margin-top: 0;
    }
  }
