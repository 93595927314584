.well {
@include make-col(10);
display: inline-block;
  position: relative;
  box-shadow: none;
  color: white;
  padding-top: 40px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  line-height: 30px;
  text-align: center;

  &:last-child {
      margin-bottom: 0;
  }

  &.grid-content-col {
    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
  }

  @include media-breakpoint-down(xs) {
    @include make-col(12);
        padding-left: 15px;
        padding-right: 15px;

        &:last-child {
            margin-bottom: 0;
        }
  }

  h3 {
      margin-bottom: 20px;
      margin-top: 0;
      padding-bottom: 32px;
      position: relative;

      &::after {
        background-image: url('../Images/layout/ueber_uns_linie_white.svg');
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 64%;
        height: 12px;
        content:"";
        position: absolute;
        left: 18%;
        right: 0;
        bottom: 0;

        @include media-breakpoint-down(xs) {
            // width: 100%;
            // top: 10px;
        }
    }
  }

  .neos-nodetypes-text {
      p > a {
          color: white;
          position: relative;

          &::after {
            content:"";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0.5em;
            border-bottom: 1px solid white;
          }
      }

      a:hover {
        font-weight: 500;
    }
  }

  .neos-nodetypes-text:first-child {
    position: relative;

    // &::after {
    //     background-image: url('../Images/layout/ueber_uns_linie_white.svg');
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     display: inline-block;
    //     width: 68%;
    //     height: 12px;
    //     content:"";
    //     position: relative;
    //     left: 0;
    //     top: 10px;

    //     @include media-breakpoint-down(xs) {
    //         width: 100%;
    //         top: 10px;
    //     }
    // }
  }

  .neos-nodetypes-text:last-child {
      text-align: left;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }

      &.button {
          text-align: center;
      }
  }



  &.dark-grey {
      background-color: #000000A6;
  }

  &.light-grey {
    background-color: #00000084;
}

  .more {

    > a {
      @include media-breakpoint-up(md) {
        line-height: $line-height-base * 2;
      }

      &:after {
        content: "\e080"; /* glyphicon-chevron-right */
        font-family: "Glyphicons Halflings";
        font-size: $font-size-small;
        line-height: $font-size-small * 0.5;
        padding-left: 5px;

        @include media-breakpoint-down(sm) {
          right: 20px;
        }
      }
    }
  }
}
