.contact-column {
  @include media-breakpoint-up(sm) {
    @include make-col(12);
  }
}

.contact-firstname,
.contact-lastname {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

@include media-breakpoint-up(sm) {
  .contact-firstname {
    padding-right: 0;
  }
}

.contact-submit {
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  @include media-breakpoint-down(xs) {
    @include make-col(12);
  }
}

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}
