#content-slider.customer-comments--slider.carousel {
    margin-bottom: 50px;

    .neos-contentcollection {
        overflow: hidden;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    .carousel-indicators {
      display: flex;
      bottom: -50px;
      z-index: 0;

      @include media-breakpoint-down(lg) {
        bottom: -40px;
      }

      @include media-breakpoint-down(sm) {
        display: contents;
      }

      li {
        max-width: 170px;
        text-align: center;
        font-size: 14px;
        width: auto;
        text-indent: 0;
        background: none;
        padding: 0 15px;
        line-height: 1.3;

        @include media-breakpoint-down(sm) {
          height: 18px;
          max-width: 100%;
        }
      }
    }

    .carousel-item, .carousel-item-backend {
        min-height: 350px;
        height: auto;

        .customer-comment-section {
            h4 {
                height: 92px;
                font-size: 28px;
                text-transform: uppercase;
                text-align: center;
            }

            .quote-section {
                @include make-col(10);
                @include make-col-offset(1);
                text-align: center;
            }
        }
    }

    .carousel-control-prev {
        width: auto;
        @media (max-width: 1200px) {
            left: -20px;
        }

        @include media-breakpoint-down(sm) {
            left: -15px;
        }
    }

    .carousel-control-next {
        width: auto;
        @media (max-width: 1200px) {
            right: -20px;
        }

        @include media-breakpoint-down(sm) {
            right: -15px;
        }
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        width: 18px;
    }

    .carousel-item-backend {
        margin-bottom: 50px;
    }
  }
