.footer {
  @extend .container-fluid;
  padding: 20px 0;
  margin-top: 90px;

  @include media-breakpoint-down(md) {
      margin-top: 42px;
      padding-bottom: 60px;
  }

  @include media-breakpoint-down(sm){
      margin-top: 30px;
      padding-bottom: 70px;
  }
}

.footer-content {
  @extend .container;
}

.footer-about, .footer-contact, .footer-social-media {
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }

  @include media-breakpoint-down(md) {
    @include make-col(4);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  padding-left: 15px;
}

.footer-social-media {
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 18px;
  }

  p {
      > span {
        margin-bottom: 6px;
        display: block;
      }
  }

  a {
    display: flex;
    margin-bottom: 8px;

    span {
       margin-left: 14px;
    }
  }

  img {
    height: 26px;
    width: 26px;
    align-self: center;
  }
}

p {
  font-size: 16px !important;

  .highlight {
      color: $color-red;
      font-weight: 500;
      font-size: 14px;
  }
      b {
          font-weight: 600;
      }

      a {
          color: inherit;
      }
  }

.footer-about > p > b {
    font-weight: 700;
}

.footer-nav {
  @include media-breakpoint-up(md) {
    @include make-col(3);
    @include make-col-offset(0);
  }

  @include media-breakpoint-down(md) {
    @include make-col(4);
    @include make-col-offset(0);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  a {
    color: inherit;

    &:hover {
      color: darken($text-color, 15%);
    }
  }
}

.footer-nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  justify-content: flex-end;

  li {
    padding-right: 20px;
    color: $color-red;
    font-weight: 500;
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 15px;
    margin-top: 10px;
    justify-content: flex-start;
  }
}
