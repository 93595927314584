body {
  > .content {
    @extend .container-fluid;
    margin: 10px auto;
    margin-bottom: 0;
    min-height: calc(70vh);
    font-weight: $font-weight-light;
    // text-align: center;
    padding-left: 0;
    padding-right: 0;

    img {
      @extend .img-fluid;
    }

    ul {
      padding-left: 20px;
    }

    table {
      @extend .table;
    }
  }

//   &.has-no-subpages {
//       .callout {
//         padding-top: 115px;

//         @include media-breakpoint-down(md) {
//             padding-top: 88px;
//           }

//           @include media-breakpoint-down(sm) {
//             padding-top: 60px;
//           }

//           @include media-breakpoint-down(sm) {
//             padding-top: 57px;
//           }
//       }
//   }
}

h1 {
    font-size: 28px;
    letter-spacing: 2.8px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    @media (max-width: 320px) {
        font-size: 19px;
    }

    strong {
        color: $color-red;
        font-weight: inherit;
    }
}

h2 {
    font-size: 28px;
    letter-spacing: 2.8px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    @media (max-width: 320px) {
        font-size: 19px;
    }

    strong {
        color: $color-red;
        font-weight: inherit;
    }
}

h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 42px;
    line-height: 30px;

    @media (max-width: 320px) {
        font-size: 18px;
    }
}

p {
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 30px;

    @include media-breakpoint-down(xs) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    @media (max-width: 320px) {
        font-size: 16px;
    }
}

.neos-contentcollection {
  @include clearfix;
}

.rebox {
    .rebox-button {
        line-height: 35px;
    }

    .rebox-close {
      line-height: 37px;
    }
}
html {
    &#referenzen {
        .callout {
            padding-top: 115px;

            @include media-breakpoint-down(md) {
              padding-top: 88px;
            }

            @include media-breakpoint-down(sm) {
              padding-top: 60px;
            }

            @include media-breakpoint-down(sm) {
              padding-top: 57px;
            }
        }
    }
}
