.breadcrumb.container {
    text-align: center;
    margin-bottom: 62px;

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        text-align: left;
        justify-content: center;
        display: flex;
    }

    .breadcrumb-list {
        list-style: none;
        display: inline-flex;
        padding-left: 0;

        @include media-breakpoint-down(md) {
            display: inline-block;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
        }

        li {

            + li:before {
                content: ">";
                padding: 0 8px;
                color: #9F9697;
                font-weight: 300;
              }

            a {
                color: #9F9697;
            }

            &.current  {
                font-weight: 600;
                color: #9F9697;
            }
        }

        @include media-breakpoint-down(md) {
            display: block;

            li {
                font-size: 13px;
                display: contents;
            }
        }
    }
}
