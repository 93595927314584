.team-member {
    // display: flex;
    position: relative;
    @extend .container;
    // left: 95px;

    .content-left, .content-right {
        @include make-col(5);
        @extend .col;

        @include media-breakpoint-down(md){
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    .quote {
        margin-top: 27px;

        > p {
            font-size: 30px;
            font-weight: 600;
            font-style: italic;
            line-height: 50px;
            color: $color-red;
            margin-bottom: 35px;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 12px;
            }
        }
    }

    .author > p {
        font-size: 16px;
    }

    .person-description > p {
        font-size:  18px;
        line-height: 28px;
        letter-spacing: -0.18px;

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
		}

        @include media-breakpoint-down(xs) {
            margin-bottom: 25px;
            text-align: center;
        }

        &:first-child {
            font-size: 22px;
            margin-bottom: 5px;
        }

        &:nth-child(2) {
            font-size: 22px;
            font-weight: 300;
            margin-bottom: 25px;
        }
    }

    &.left {
        .content-right {
            // padding-left: 40px;
            float: right;
            position: relative;
            right: 95px;

            @include media-breakpoint-down(lg) {
                right: 75px;
            }

            @include media-breakpoint-down(md) {
                right: 0;
            }

            .name >p{
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        .content-left {
            float: left;
            position: relative;
            left: 95px;

            @include media-breakpoint-down(lg) {
                left: 75px;
            }


            @include media-breakpoint-down(md) {
                left: 0;
            }
        }

        .quote, .author {
            text-align: right;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .quote {
            padding-left: 5%;
            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }

        }

        .author > p {
            padding-left: 35%;
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                margin-bottom: 25px;
            }
        }

        &::after {
            clear: both;
        }
    }

    &.right {
        .content-right {
            position: relative;
            // padding-right: 40px;
            padding-left: 0;
            float: left;
            left: 95px;

            @include media-breakpoint-down(lg) {
                left: 75px;
            }

            @include media-breakpoint-down(md) {
                left: 0;
            }


            .name >p{
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        .content-left {
            position: relative;
            float: right;
            right: 95px;

            @include media-breakpoint-down(lg) {
                right: 75px;
            }


            @include media-breakpoint-down(md) {
                right: 0;
            }
        }

        .quote, .author {
            text-align: left;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .quote {
            padding-right: 5%;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }

        .author > p {
            padding-right: 35%;

            @include media-breakpoint-down(sm) {
                padding-right: 0;
            }
        }
    }
    &::after {
        clear: both;
    }
}
