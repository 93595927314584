
.team-slider {
    .slick-arrow {
        height: 24px;
        display: block;
        &::before {
            content:url("../Images/layout/arrow-left.svg");
            display: flex;
        }

        &.slick-next {
            transform: rotate(180deg);
            transform-origin:center;
            right: -6%;

            @media (max-width: 1200px) {
                right: -3%;
            }

            @include media-breakpoint-down(lg) {
                right: -4%;
            }

            @include media-breakpoint-down(sm) {
                right: 0;
            }
        }

        &.slick-prev {
            left: -6%;
            z-index: 10;

            @media (max-width: 1200px) {
                left: -3%;
            }

            @include media-breakpoint-down(lg) {
                left: -4%;
            }

            @include media-breakpoint-down(sm) {
                left: 0;
            }
        }
    }

    .slick-dots {
        li {
            margin: 0;
        }

        button {
            &::before {
                font-size: 20px;
            }
        }
    }

    p {
        font-size: 22px;
        margin-bottom: 0px;
        margin-top: 5px;
        line-height: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }

        &:nth-child(2) {
            font-weight: 500;
            height: 27px;
            margin-top: 8px;
        }
    }

    .team-member-slider--item {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(lg) {
            padding: 5px;
        }
    }
}
