//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: zeitung, "Helvetica Neue", Arial, sans-serif;
$slick-font-family: zeitung, "Helvetica Neue", Arial, sans-serif !important;
$font-size-base: 1rem;
$font-size-small: 0.8rem;
$font-size-h1: 1.75rem;
$font-size-h2: 1.75rem;
$font-size-h3: $font-size-base;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$line-height-base: 1.7;
$headings-line-height: 1.16;
$headings-font-weight: $font-weight-bold;

// Colors
$color-white: #ffffff;
$color-black: #282828;
$color-red: #D10B11;
$color-gray: #eeeeee;
$brand-primary: $color-red;
$brand-secondary: $color-gray;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: transparentize($color-white, 0.7);

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;
