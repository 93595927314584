.button {
    text-align: center;

    &.btn-brand-secondary {
        background-color: #E3E3E3FC;
        color: black;
    }

    &.fullWidth {
        display: flex;
        justify-content: flex-start;
        margin: 0 0;

        @include media-breakpoint-down(md) {
            justify-content: center
        }

        a {
            width: 308px;
        }
    }

    &.left {
        text-align: left;
    }
}

.btn-brand-primary {
    border-radius: 0;
    border: none;
    background-color: $color-red;
    color: white;
    padding: 10px 20px;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 16px;
    display: inline-block;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    text-align: center;

    &:hover {
      text-decoration: none;
      background-color: #C30B11;
      color: $white;
    }
  }

  .btn-brand-secondary {
    @extend .btn-brand-primary;
    background-color: #E3E3E3FC;
    border: none;
    color: black;
    padding: 10px 20px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    text-align: center;

    @include media-breakpoint-down(md) {
        display: inline-block;
    }

    &:hover {
      text-decoration: none;
      background-color: #D8D9D9;
      color: black;
    }
  }

  .btn-brand-white {
    @extend .btn-brand-primary;
    background-color: white;
    color: black;

    &:hover {
        background-color: #D8D9D9;
        color: black;
      }
  }

  .btn-brand-ghost {
    @extend .btn-brand-primary;
    background-color: unset;
    border: 1px solid white;
    color: white;

    &:hover {
        background-color: white;
        color: black;
    }
  }
