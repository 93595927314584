.milestone-collection--wrapper {
    position: relative;
    .tab-content {
        @extend .container;
        margin-bottom: 70px;

        > .tab-pane {
            display: none;
        }

        .active {
            display: flex;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        .milestone--content {
            &.left {
                @include make-col(5);
                @include make-col-offset(1);

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px
                }
            }

            &.right {
                @include make-col(5);
                text-align: left;
                .neos-nodetypes-headline {
                    margin-left: 0;
                }


                p {
                    display: contents;
                    font-size: 16px;
                    line-height: 28px;
                    margin-bottom: 0
                }


                h3 {
                    margin-top: 0;
                    margin-bottom: 6px;
                }
            }

            &.right, &.left {
                @extend .col;
                @include media-breakpoint-down(md) {
                    @include make-col(12);
                    @include make-col-offset(0);
                }
            }
        }
    }

    .timeline {
        list-style: none;
        position: relative;
        padding-left: 0;
        margin-bottom: 0;

        &::after {
            content:"";
            position: absolute;
            bottom: -32px;
            background-image: url('../Images/layout/annweiler-meilenstein-backgr-desktop.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: inline-block;
            width: 100%;
            height: 100%;
            min-height: 113px;

            @media (max-width: 1828px) {
                min-height: 177px;
                bottom: -41px;
            }

            @media (max-width: 1440px) {
                height: 165px;
                bottom: -40px;
                background-size: cover;
            }

            @media (max-width: 1200px) {
                height: 154px;
                bottom: -36px;
            }

            @include media-breakpoint-down(lg) {
                height: 165px;
                bottom: -40px;
            }

            @include media-breakpoint-down(md) {
                background-image: url('../Images/layout/annweiler-meilenstein-backgr-tablet.svg');
                background-position: unset;
                height: 125px;
                bottom: -13px;
            }

            @include media-breakpoint-down(sm) {
                background-image: url('../Images/layout/annweiler-meilenstein-backgr-mobile.svg');
                height: 194px;
                bottom: -16px;
            }

            @media (max-width: 320px) {
                height: 172px;
                bottom: -8px;
            }
        }

        .nav-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
            z-index: 10;
            padding: 0 148px;

            @media (max-width: 1440px) {
                padding: 0 80px;
            }

            @media (max-width: 1200px) {
                padding: 0 50px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 30px;
            }

            @media (max-width: 320px) {
                padding: 0 15px;
            }
        }


        .btn {
            color: white;
            font-weight: 600;
            position: relative;
            text-align: end;
            width: 90px;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            @media (max-width: 1200px) {
                text-align: center;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                width: 72px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 14px;
                width: 20%;
                text-align: center;
            }

            &.active {
                position: relative;
                color: $color-red;

                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 4px;
                    transform: translateX(-68%);
                    background-image: url('../Images/layout/timeline_highlight_white.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 142px;
                    height: 30px;
                    background-position: left;
                    z-index: -1;

                    @media (max-width: 1200px) {
                        left: 25%;
                    }


                    @include media-breakpoint-down(md) {
                        width: 126px;
                        height: 40px;
                        top: 0;
                        transform: translateX(-65%);
                        left: auto;
                    }

                    @include media-breakpoint-down(sm) {
                        transform: translateX(-63%);
                        width: 107px;
                        top: -2px;
                    }
                }
            }
        }
    }
}


.milestone-content--wrapper {
    .neos-nodetypes-text, .neos-nodetypes-headline {
        margin: 0;
    }

    img {
        margin-top: 20px;
    }

    .grid-content {
        text-align: center;
        margin-bottom: 40px;
    }
}
