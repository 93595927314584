$header-logo-height-md: 100px;
$header-logo-height-xs: $header-logo-height-md/2;

.header-section{
    @extend .container-fluid;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    height: 114px;
    transition: all 0.2s ease;

    @include media-breakpoint-down(md) {
        height: 88px;
    }

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    &.active {
        .header-logo {
            img {
                visibility: hidden;
            }
        }

        .header-nav-list {
            @include media-breakpoint-down(md) {
                margin-top: 50px;
            }
        }
    }
}

.header {
  @extend .container;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-logo {
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }

  @include media-breakpoint-down(md) {
    position: absolute !important;
    left: 20px;
    z-index: 100;
  }

  img {
    height: 200px;
    position: absolute;
    top: 30%;
    left: 20%;
    z-index: 100;

    @media (max-width: 1440px) {
       left: 10%;
    }

    @media (max-width: 1024px) {
        left: 5%;
        top: 22%;
     }

     @include media-breakpoint-up(lg) {
        height: 200px;
     }

     @include media-breakpoint-down(md) {
        height: 160px;
     }


     @include media-breakpoint-down(xs) {
        height: 120px;
     }
  }
}

.header-nav {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(9);
  }

  @include media-breakpoint-up(md) {
    margin-top: 54px;
  }

  a {
    color: inherit;
    font-weight: $font-weight-normal;
    font-size: 20px;

    @include media-breakpoint-down(lg) {
        font-size: 18px;
    }

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  .header-nav-list > li {
    &.active > a,
    &.current > a {
      font-weight: 600;
    }
  }
}

.header-nav-list,
.header-nav-sub {
  list-style-type: none;
}

.header-nav-list {
  justify-content: flex-end;
  display: flex;
  padding: 0;

  li {
      &:nth-child(3) {
          .header-nav-sub {
            display: none;

            // @include media-breakpoint-down(md) {
            //     display: block;
            // }
          }
      }
  }

  @include media-breakpoint-down(md) {
    display: block;
    overflow-y: scroll;
    min-height: 300px;
    height: calc(100vh - 100px);
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 114px;

    li  {
        border-bottom: 1px solid black;
        padding: 10px 0;
        &:last-child {
            border-bottom: none;
        }
        > a {
            color: $color-red;
        }
    }
  }

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 100px);
  }

  @include media-breakpoint-up(lg) {
    text-align: left;

    li {
      display: inline-block;
      padding-left: 44px;

        &.active > a,
        &.current > a {
            font-weight: 600;
        }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.header-nav-sub {
    z-index: 10;
    color: white;

@include media-breakpoint-up(md) {
    text-align: left;
    min-height: 72px;
    max-height: 174px;
  }

  @include media-breakpoint-down(lg) {
    min-height: 72px;
    max-height: 168px;
    padding-right: 30px !important;
  }

  @include media-breakpoint-down(md) {
    padding-left: 10px;
    max-height: inherit;
  }

  .second-level-sub-navigation {
      @extend .container;
      padding-top: 18px !important;
      display: block;
      padding-left: 284px;
      padding-right: 0;
      text-align: right;

      li  {
        padding-left: 0;
        margin-bottom: 18px;

        > a {
            padding-left: 44px;
            font-size: 18px;

            &:focus {
                color: $color-white;
            }

            &:hover {
                color: #E9ECED;
            }

            @include media-breakpoint-down(lg) {
                 padding-left: 30px;
            }

            @include media-breakpoint-down(md) {
                color: black;
                font-weight: 300;
            }

         }

        &:last-child {
            a {
                padding-right: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        text-align: left;

        li {
            margin-bottom: 0;
            &.current {
                a {
                    color: $color-red;
                    font-weight: 600;
                }
            }

            a {
                padding-left: 0;
            }
        }

        &:last-child {
            padding-right: 0;
        }

        a {
            &:hover {
                color: inherit;
            }
         }
      }

      @media (max-width: 1440px) {
          left: 282px;
      }

      @include media-breakpoint-down(lg) {
          max-width: 100%;
          left: auto;
          padding-left: 25%;
      }

      @include media-breakpoint-down(md) {
          display: contents;

          li {
              list-style: none;
              padding-left: 15px;
          }
      }
  }

}

@include media-breakpoint-up(lg) {
  .header-nav-sub {
    background-color: $color-red;
    display: none;
    position: absolute;
    top: 91%;
    right: 0;
    left: 0;
    padding: 0 $grid-gutter-width/2;
    margin: 10px 0;
    font-size: $font-size-small;
  }

  .active .header-nav-sub {
    display: block;
  }
}

.header-nav-toggle {
  @include make-col-offset(9);
  color: #000;

  .navbar-toggler {
      position: absolute;
      z-index: 5;
      right: 10px;
      top: 30%;
      color: #000;

      @include media-breakpoint-down(xs) {
        top: 50%;
       }

      &:focus {
          outline: none;
      }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content.collapse {
    display: block;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}

@include media-breakpoint-down(md) {
.header-nav-content {
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 0 15px;
    background-color: white;
    box-shadow: 0px 10px 10px -15px #111;
    }
}

#nav.active {
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    height: 82px;

    @include media-breakpoint-down(md) {
        height: 62px;
    }

    .header {
        height: 50px;

        @include media-breakpoint-down(md) {
            height: 70px;
        }
    }

    .header-nav {
      margin-top: 16px;

        .header-nav-toggle {
            .navbar-toggler {
                @include media-breakpoint-down(md) {
                top: 24%;
                }
            }
        }
    }

    .header-nav-sub {
        top: 74%;

        @include media-breakpoint-up(lg) {
         visibility: hidden;
        }
    }

    .header-logo {

        img {
            position: relative;
        }

    .logo {
        background-image: url("../Images/layout/annweiler_logo_small.svg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 50px;
        z-index: 20;
        margin-top: 6px;
        animation: fadeIn ease 0.8s;

        @include media-breakpoint-down(md) {
            width: 90%;
            height: 80px;
        }


        @include media-breakpoint-down(sm) {
            height: 50px;
        }

        @keyframes fadeIn {
        0% {
            opacity:0.5;
        }
        100% {
            opacity:1;
        }
        }
    }

     @media (max-width: 768px){
       .header-nav {
         margin-top: 0px;
       }
    }
  }
}
