.carousel {
  margin: $line-height-base auto;

  .carousel-item {
      overflow: hidden;
      background-repeat: no-repeat;
  }

  .carousel-control {
    background-image: none;
  }

  .carousel-control-icon {
    background-repeat: no-repeat;
    background-size: 45px;
    transition: opacity .1s;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}

#content-slider {
    .carousel-control-prev,
    .carousel-control-next {
      height: 35px;
      width: 35px;
      top: 50%;

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
          width: 70px;
        //   position: absolute;
          background-repeat: no-repeat;
          background-image: url("../Images/layout/arrow-left.svg");
      }

      .carousel-control-prev-icon {
        left: 0;
      }

      .carousel-control-next-icon {
        transform: rotate(180deg);
        right: 0;
      }
    }

    .carousel-control-prev {
        left: -50px;
      }

      .carousel-control-next {
        right: -50px;
      }
  }
