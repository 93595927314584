.jobs--wrapper {
    p {
        text-align: center;
        @extend h3;
    }

    .job-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        a {

            min-width: 320px;
            width: auto;
            height: 100%;

            &:nth-child(2n) {
                margin-left: 30px;
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }

            @include media-breakpoint-down(sm) {
                min-width: 100%;
            }
        }
    }
}
