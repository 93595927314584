.services-box {
    @extend .col;
    background-color: #D10B11;
    padding: 46px 9px;
    text-align: center;
    width: auto;
    height: 310px;

     @media (max-width: 1440px) {
        @include make-col(3);
        padding: 46px 15px;
        margin-bottom: 0;
        margin-right: 0;
        height: 298px;

        &:last-child {
             margin-right: 0;
        }
     }

     @include media-breakpoint-down(lg) {
        height: 306px;
     }

     @include media-breakpoint-down(md) {
        flex: 0 0 49%;
        max-width: 49%;
        width: 49%;
        height: auto;
        margin-right: 0;
        margin-bottom: 0;
        padding: 46px 20px;

        &:nth-child(2) {
            margin-right: 0;
       }

        &:last-child {
            margin-right: 0;
       }
     }

     @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
     }

     @include media-breakpoint-down(xs) {
        @include make-col(10);
        @include make-col-offset(1);
        height: auto;
        margin-bottom: 0;
        margin-right: 0;
     }

    &:last-child {
    margin-right: 0 !important;
    }


    p {
        color: white;
        font-size: 18px;
        line-height:  33px;
        margin-bottom: 0;
        margin-top: 30px;

        @include media-breakpoint-down(lg) {
            font-size: 18px;
            font-weight: 400;
         }

        a {
            color: inherit;
        }
    }

    img {
        width: 33%;
    }

    .walldecoration-icon, .stuccodesign-icon, .restaurierung-icon, .heatinsulation-icon {
        img {
            max-height: 75px;
        }
    }
}
.services-wrapper {
    position: relative;
    top: -152px;

    @include media-breakpoint-down(md) {
        display: block;
        top: 0;
        overflow: scroll;
        text-align: center;
     }

        > .neos-contentcollection {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 15px;

            @media (max-width: 1440px) {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 12px;
            }

            @include media-breakpoint-down(md) {
                // display: grid;
                // grid-template-columns: 49% 49%;
                // grid-gap: 15px;
             }

             @include media-breakpoint-down(xs) {
                display: grid;
                grid-template-columns: 100%;
             }

        div:last-child {
            margin-right: 0;
        }
    }
}
.services-about-wrapper {

    > .neos-contentcollection {
        display: flex;
        margin-bottom: 50px;

        .services-box {
            margin-right: 10px;
        }

        @media (max-width: 1440px) {
            flex-wrap: wrap;

            .services-box {
                margin-bottom: 10px;
            }

        }

        @include media-breakpoint-down(md) {
            .services-box {
                margin-bottom: 10px;
                flex: 0 0 47%;
                max-width: 47%;
                width: 47%;
            }
        }

        @include media-breakpoint-down(sm) {
            .services-box {
                margin-bottom: 10px;
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(xs) {
            display: grid;
            grid-template-columns: 100%;
        }

        div:last-child {
            margin-right: 0;
        }
    }
}
