.callout {
  margin: 0 auto 38px !important;
  padding-top: 186px;

  &.no-sub {
      padding-top: 115px;

      @include media-breakpoint-down(md) {
        padding-top: 88px;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 60px;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 57px;
      }
  }

  @include media-breakpoint-down(md) {
    padding-top: 88px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 57px;
    margin: 0 auto 20px !important;
  }

  .carousel-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $brand-secondary;
    margin-right: 0;

    @include media-breakpoint-up(md) {
        height: 445px;
    }

    @media (max-width: 1200px) {
        height: 390px;
    }

    @include media-breakpoint-down(md) {
        height: 250px;
    }

    @include media-breakpoint-down(sm) {
        height: 184px;
    }

    @media (min-width: 2560px) {
        height: 556px;
    }

    &.marginTop {
        margin-top: 50px;

        @include media-breakpoint-down(lg) {
            margin-top: 40px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
  }
}

.callout.home {
    padding-top: 114px;

    @include media-breakpoint-down(xs) {
        padding-top: 65px;
    }

    .carousel-item {
      height: 845px;
      @include media-breakpoint-down(sm) {
        height: 140px;
        background-size: 100%;
      }
    }
    .callout-caption-box {
      background-color: $brand-primary;
    }
  }

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  display: none;
  position: absolute;
  padding: 20px;
  background-color: $callout-caption-bgcolor;
  color: $brand-primary;

  @include media-breakpoint-up(sm) {
    display: block;
    left: 15%;
    top: 40%;
    width: 350px;
  }
}
